import styled from "@emotion/styled"
import { Formik, FormikErrors } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"
import { SalesFormBody } from "../../backend/functions/sales/interfaces"
import { SalesFormBodyStruct } from "../../backend/functions/sales/schemas"
import IllustrationAudit from "../../static/illustrations/audits/audit.inline.svg"
import IllustrationCircle from "../../static/illustrations/audits/circle.inline.svg"
import IllustrationSquare from "../../static/illustrations/audits/square.inline.svg"
import IllustrationTriangle from "../../static/illustrations/audits/triangle.inline.svg"
import IllustrationTypesOfTestingShapes from "../../static/illustrations/audits/types-of-testing-shapes.inline.svg"
import ImageCertificationIcon from "../../static/images/audit/certification-icon.inline.svg"
import ImageCertification from "../../static/images/audit/certification.inline.svg"
import ImageTriangle from "../../static/images/audit/triangle.inline.svg"
import { trackFormSubmitEvent } from "../utils/track-ga-event"
import {
  Footer,
  Header,
  ErrorBoundary,
  ConsultationTeaserMinimal,
  ConsultationTeaser,
} from "../components"
import { SEO } from "../components/SEO"
import {
  Box,
  BoxProps,
  Button,
  Container,
  FormMessage,
  LinkAnchor,
  Heading,
  Input,
  Lead,
  List,
  ListItem,
  ListOrdered,
  SubHeading,
  Text,
  TextBold,
  Title,
  Tooltip,
  ButtonSize,
  ButtonTheme,
} from "../elements"
import { Colors, Fonts } from "../materials"
import { sendSalesForm } from "../utils/api/send-sales-form"

const Intro: React.FC<{}> = () => (
  <Box pt={10}>
    <Container pt={[5, 7]} pb={[8, 8, 8, 10]}>
      <Title id="section1" color={Colors.oc.blue[9]}>
        Accessibility Audit
      </Title>
      <Box mt={4} />
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={["space-between"]}
        alignItems="flex-start"
      >
        <Box flex="1 1 auto">
          <Box maxWidth="copy">
            <Lead>
              If you need a complete report on your webite or to be compliant
              with{" "}
              <LinkAnchor
                href="https://www.ada.gov/2010ADAstandards_index.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                ADA
              </LinkAnchor>
              ,{" "}
              <LinkAnchor
                href={"https://www.w3.org/TR/WCAG21/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                WCAG
              </LinkAnchor>
              , or{" "}
              <LinkAnchor
                href="https://www.section508.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Section 508
              </LinkAnchor>
              , an accessibility audit is ideal for you.
            </Lead>
            <Box mt={4} />
            <Lead>
              Our audits are more than just a report. For every accessibility
              issue, you’ll get recommendations on how to fix them.
            </Lead>
          </Box>
        </Box>
        <Box ml={6} />
        <Box
          display="flex"
          justifyContent="center"
          width={["100%", "100%", "100%", "auto"]}
          mt={[8, 8, 8, 0]}
        >
          <ConsultationTeaserMinimal />
        </Box>
      </Box>
    </Container>
  </Box>
)

const OurProcess: React.FC<{}> = () => (
  <Box
    as="section"
    aria-labelledby="section2"
    bg={Colors.oc.gray[0]}
    css={{
      borderTop: `1px solid ${Colors.oc.gray[3]}`,
    }}
  >
    <Container
      pt={[8, 8, 9]}
      pb={[9, 11]}
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
        width={1}
      >
        <Box flex="1 1 auto">
          <Heading id="section2" color={Colors.oc.blue[9]}>
            Our Process
          </Heading>
          <Box maxWidth="55ch" mt={2}>
            <Lead>
              Our accessibility audit has 3 phases. The duration of each will
              depend on your needs and the complexity of your website.
            </Lead>
          </Box>
          <Box mt={5} />
          <ListOrdered
            css={{
              position: "relative",
              ":before": {
                content: `''`,
                display: "block",
                width: "2px",
                position: "absolute",
                top: 0,
                bottom: 0,
                height: "100%",
                left: "12px",
                background: Colors.oc.blue[9],
              },
            }}
          >
            <ListItem
              display="flex"
              alignItems="flex-start"
              maxWidth="copy"
              pt={8}
              pb={8}
            >
              <Circle
                flex="0 0 auto"
                mt={"2px"}
                width={25}
                height={25}
                mr={[4, 5]}
              />
              <Box flex="1 1 auto">
                <SubHeading color={Colors.oc.blue[9]}>Assessment</SubHeading>
                <Box mt={4} />
                <List>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Product analysis
                  </ListItem>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Issues report
                  </ListItem>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Recommended solutions
                  </ListItem>
                </List>
              </Box>
            </ListItem>

            <ListItem
              display="flex"
              alignItems="flex-start"
              maxWidth="copy"
              pb={8}
            >
              <Circle
                flex="0 0 auto"
                mt={"2px"}
                width={25}
                height={25}
                mr={[4, 5]}
              />
              <Box flex="1 1 auto">
                <SubHeading color={Colors.oc.blue[9]}>Improvement</SubHeading>
                <Box mt={4} />
                <List>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Training
                  </ListItem>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Support
                  </ListItem>
                </List>
              </Box>
            </ListItem>
            <ListItem
              display="flex"
              alignItems="flex-start"
              maxWidth="copy"
              pb={8}
            >
              <Circle
                flex="0 0 auto"
                mt={"2px"}
                width={25}
                height={25}
                mr={[4, 5]}
              />
              <Box flex="1 1 auto">
                <SubHeading color={Colors.oc.blue[9]}>Compliance</SubHeading>
                <Box mt={4} />
                <List>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="flex-start"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    Re-evaluation
                  </ListItem>
                  <ListItem
                    display="flex"
                    alignItems="flex-start"
                    color={Colors.oc.blue[9]}
                    mb={1}
                  >
                    <Box
                      width={16}
                      height={16}
                      display="flex"
                      alignItems="baseline"
                      mr={2}
                      flex="0 0 auto"
                      mt={1}
                    >
                      <Plus />
                    </Box>
                    <span>
                      <LinkAnchor
                        href="https://www.itic.org/policy/accessibility/vpat"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        VPAT 2.3
                      </LinkAnchor>
                      &nbsp;&{" "}
                      <LinkAnchor
                        href="https://www.w3.org/WAI/planning/statements/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Accessibility Statement
                      </LinkAnchor>
                    </span>
                  </ListItem>
                </List>
              </Box>
            </ListItem>
          </ListOrdered>
          <Box css={{ position: "relative", overflow: "visible" }}>
            <Box
              display="flex"
              css={{
                position: "absolute",
                top: "-1px",
                left: "-3px",
              }}
            >
              <ImageTriangle />
            </Box>
            <Box pt={6} />
            <ExpandableSvgContainer>
              <ImageCertification />
            </ExpandableSvgContainer>
            <Box display="flex" alignItems="baseline">
              <Box width={16} mr={1}>
                <ImageCertificationIcon />
              </Box>
              <Text color={Colors.oc.blue[9]} fontSize={2}>
                Aditus Certificate
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          flex={["1 1 auto", "0 0 auto"]}
          pl={[0, 0, 0, 4]}
          mt={[9, 7, 7, 0]}
          display="flex"
          alignItems="flex-start"
          justifyContent={["center", "flex-start"]}
        >
          <Box
            maxWidth={[300]}
            py={4}
            px={5}
            // bg={Colors.oc.white}
            border={`2px solid ${Colors.oc.gray[3]}`}
            borderRadius={10}
          >
            <SubHeading color={Colors.oc.blue[9]} lineHeight={1.3}>
              We can help you to be an accessibility expert.
              {/* Consulting <em>&</em> Training */}
            </SubHeading>
            <Box mt={2} />
            <Text>
              If you need a tailored service, we also offer&nbsp;
              <strong>consulting</strong> and <strong>training</strong>.
            </Text>
            <Box mt={4} />
            <Text>
              <LinkAnchor href="mailto:liliana@aditus.io">
                Drop us an email
              </LinkAnchor>{" "}
              to know more about these services.
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>
)

const Warning: React.FunctionComponent = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M12,18.75h-1.63918e-08c-0.207107,9.05293e-09 -0.375,0.167893 -0.375,0.375c9.05293e-09,0.207107 0.167893,0.375 0.375,0.375c0.207107,-9.05293e-09 0.375,-0.167893 0.375,-0.375v1.63918e-08c0,-0.207107 -0.167893,-0.375 -0.375,-0.375v0"
      strokeLinecap="round"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      d="M12,15.75v-7.5"
    />
    <path
      d="M13.621,1.76l3.9326e-08,8.00607e-08c-0.43975,-0.895254 -1.52198,-1.26451 -2.41724,-0.824762c-0.358575,0.176133 -0.648629,0.466187 -0.824762,0.824762l-9.473,19.298l-1.5871e-08,3.22857e-08c-0.370583,0.753864 -0.0598732,1.66541 0.69399,2.03599c0.209097,0.102788 0.439014,0.156164 0.67201,0.156009h19.456l4.34975e-08,2.88658e-11c0.840025,0.000557409 1.52145,-0.679965 1.52201,-1.51999c0.000154607,-0.232996 -0.0532212,-0.462912 -0.156009,-0.67201Z"
      strokeLinecap="round"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    />
  </svg>
)

const SVG = styled("svg")`
  width: 100%;
  height: auto;
`

const ExpandableSvgContainer = styled<BoxProps>(Box)`
  svg {
    max-width: 100%;
  }
`

const Plus: React.FC<{}> = () => (
  <SVG viewBox="0 0 25 25">
    <g transform="scale(1.04167)">
      <path
        fill="currentColor"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.248,20.882l-6.631,-6.632l-2.12137e-07,-2.23935e-07c-1.12427,-1.18679 -1.07358,-3.06027 0.113207,-4.18454c1.14105,-1.08094 2.92797,-1.08158 4.06979,-0.00145846l4.218,4.218l7.9,-10.533l-3.52991e-08,4.71086e-08c0.980581,-1.30864 2.83636,-1.57458 4.145,-0.594c1.30864,0.980581 1.57458,2.83636 0.594,4.145l-9.947,13.265l-2.46916e-08,3.29107e-08c-0.981406,1.30809 -2.83741,1.57292 -4.1455,0.591514c-0.11185,-0.0839165 -0.217615,-0.175651 -0.316502,-0.274514Z"
      />
    </g>
  </SVG>
)

const HowWeTest: React.FC<{}> = () => {
  const {
    total11y,
    axe,
    pa11y,
    puppeteer,
    javascript,
    voiceOver,
    nvda,
  } = useStaticQuery(graphql`
    fragment logo on File {
      childImageSharp {
        fluid(maxHeight: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fragment profilePicture on File {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      total11y: file(relativePath: { eq: "images/audit/total11y-logo.png" }) {
        ...logo
      }

      axe: file(relativePath: { eq: "images/audit/axe-core-logo.png" }) {
        ...logo
      }

      pa11y: file(relativePath: { eq: "images/audit/pa11y-logo.png" }) {
        ...logo
      }

      puppeteer: file(relativePath: { eq: "images/audit/puppeteer-logo.png" }) {
        ...logo
      }

      javascript: file(
        relativePath: { eq: "images/audit/javascript-logo.png" }
      ) {
        ...logo
      }

      voiceOver: file(
        relativePath: { eq: "images/audit/voice-over-logo.png" }
      ) {
        ...logo
      }

      nvda: file(relativePath: { eq: "images/audit/nvda-logo.png" }) {
        ...logo
      }
    }
  `)
  return (
    <Box
      as="section"
      aria-labelledby="section3"
      bg={Colors.oc.gray[1]}
      pt={[9, 10]}
      pb={10}
      css={{
        borderBottom: `1px solid ${Colors.oc.blue[2]}`,
        overflow: "hidden",
      }}
    >
      <Container maxWidth="m" display="flex" justifyContent="center">
        <Box display="flex" flexWrap="wrap" m={-7}>
          <Box p={7}>
            <Heading id="section3" color={Colors.oc.blue[9]}>
              How do we test your website?
            </Heading>
            <Box mt={3} />
            <Box maxWidth="48ch">
              <Lead>
                We use different testing techniques to ensure we deliver{" "}
                <strong>quick and reliable</strong> reports.
              </Lead>
            </Box>
          </Box>
          <ExpandableSvgContainer
            p={7}
            flex={["1 1 auto", "0 0 auto"]}
            maxWidth={450}
            mx="auto"
            ml={[0, 0, 0]}
          >
            <IllustrationTypesOfTestingShapes />
          </ExpandableSvgContainer>
        </Box>
      </Container>
      <Container maxWidth="l">
        <Box mt={[8, 9]} />
        <Box display="flex" flexWrap="wrap" justifyContent="center" m={-7}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} p={7}>
            <Box display="flex" alignItems="flex-start">
              <Box
                mt={1}
                width={20}
                height={20}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={2}
              >
                <IllustrationCircle />
              </Box>
              <Box>
                <TextBold as="h3">Automated Testing</TextBold>
              </Box>
            </Box>
            <Box maxWidth="copy">
              <Text mt={1}>
                We start by using automated testing tools. While this only
                identifies around 30% of the errors, it gives us a good
                perception on your website.
              </Text>
            </Box>
            <Box mt={6} />
            <Box mt={"auto"} display="flex" alignItems="center" mx={-2}>
              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Tota11y">
                  <Box
                    as="button"
                    width={30}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img
                      fluid={total11y.childImageSharp.fluid}
                      alt="Total11y logo"
                    />
                  </Box>
                </Tooltip>
              </Box>

              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Axe Core">
                  <Box
                    as="button"
                    width={40}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img
                      fluid={axe.childImageSharp.fluid}
                      alt="axe-core logo"
                    />
                  </Box>
                </Tooltip>
              </Box>

              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Pa11y">
                  <Box
                    as="button"
                    width={28}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img fluid={pa11y.childImageSharp.fluid} alt="pa11y logo" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} p={7}>
            <Box display="flex" alignItems="flex-start">
              <Box
                mt={1}
                width={20}
                height={20}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={2}
              >
                <IllustrationSquare />
              </Box>
              <Box>
                <TextBold as="h3">Semi-Automated Testing</TextBold>
              </Box>
            </Box>
            <Box maxWidth="copy">
              <Text mt={1}>
                We write functional tests that automatically interact with your
                website. This reveals accessibility issues that were otherwise
                not detectable with fully automated tools.
              </Text>
            </Box>
            <Box mt={6} />
            <Box mt={"auto"} display="flex" alignItems="center" mx={-2}>
              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Javascript">
                  <Box
                    as="button"
                    width={30}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img
                      fluid={javascript.childImageSharp.fluid}
                      alt="javascript logo"
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Puppeteer">
                  <Box
                    as="button"
                    width={25}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img
                      fluid={puppeteer.childImageSharp.fluid}
                      alt="Puppeteer logo"
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} p={7}>
            <Box display="flex" alignItems="flex-start">
              <Box
                mt={1}
                width={20}
                height={20}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={2}
              >
                <IllustrationTriangle />
              </Box>
              <Box>
                <TextBold as="h3">Manual Testing</TextBold>
              </Box>
            </Box>
            <Box maxWidth="copy">
              <Text mt={1}>
                {/* A human review is always necessary to have a full picture. */}
                {/* Finally, we test your website as a person with disabilities would. */}
                Automated testing only gets us so far. At last, we manually test
                your website. This makes sure the report you get is accurate and
                reliable.
                {/* We cannot check all accessibility aspects automatically.
                    Human judgement is required.
                    Sometimes evaluation tools can produce false or misleading results.
                    Web accessibility evaluation tools can not determine accessibility, they can only assist in doing so. */}
                {/* To detect common accessibility errors, we use industry proven software than can quickly report around 30% of the accessibility errors. */}
              </Text>
            </Box>
            <Box mt={6} />
            <Box mt={"auto"} display="flex" alignItems="center" mx={-2}>
              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="Voice Over">
                  <Box
                    as="button"
                    width={30}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img
                      fluid={voiceOver.childImageSharp.fluid}
                      alt="Voice Over logo"
                    />
                  </Box>
                </Tooltip>
              </Box>

              <Box px={2} display="flex" alignItems="center">
                <Tooltip label="NVDA">
                  <Box
                    as="button"
                    width={30}
                    css={{
                      border: 0,
                      padding: 0,
                      background: "none",
                    }}
                  >
                    <Img fluid={nvda.childImageSharp.fluid} alt="NVDA logo" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const Circle: React.FC<BoxProps> = props => (
  <Box
    bg={Colors.oc.blue[9]}
    display="flex"
    alignItems="center"
    justifyContent="center"
    css={{
      borderRadius: "50%",
      fontWeight: Fonts.mono.weight,
      fontFamily: Fonts.mono.family,
      fontSize: "3rem",
      lineHeight: 1,
    }}
    {...props}
  />
)

export default function AccessibilityAuditsAndConsultingPage() {
  const TITLE = "Accessibility Audit - Free Accessibility Consultation - Aditus"
  const DESCRIPTION = `If you need a complete report on your webite or to be compliant with ADA, WCAG, or Section 508, an accessibility audit is ideal for you.`
  const KEYWORDS =
    "accessibility, testing, a11y, audit, compliance, checker, consulting"
  const URL = "https://www.aditus.io/accessibility-audit/"
  const OG_IMAGE = "https://www.aditus.io/social/accessibility-audit.png"
  const OG_IMAGE_ALT =
    "Text reading 'Accessibility Audit' with the Aditus logo next to it"

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        keywords={KEYWORDS}
        jsonLd={{
          "@type": "WebPage",
          url: URL,
          name: TITLE,
          description: DESCRIPTION,
        }}
      >
        <meta property="fb:app_id" content="824671391240758" />
        <meta property="og:url" content={URL} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:image" content={OG_IMAGE} />
        <meta property="og:image:alt" content={OG_IMAGE_ALT} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:site_name" content="Aditus" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@aditus_a11y" />
        <meta name="twitter:url" content={URL} />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESCRIPTION} />
        <meta name="twitter:image" content={OG_IMAGE} />
        <meta name="twitter:image:alt" content={OG_IMAGE_ALT} />
      </SEO>
      <Header />
      <main>
        <ErrorBoundary>
          <Intro />
          <OurProcess />
          <ConsultationTeaser />
          <HowWeTest />
        </ErrorBoundary>
      </main>
      <Footer />
    </>
  )
}
